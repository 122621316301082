var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "alert alert-danger alert-report" },
    [
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "alert",
            "aria-label": "Close",
            title: "Usuń",
          },
          on: {
            click: function ($event) {
              return _vm.$refs.modal.open()
            },
          },
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      ),
      _vm._v(" "),
      _c("vue-username", {
        staticClass: "alert-link",
        attrs: { user: _vm.flag.user },
      }),
      _vm._v("\n  zgłosił\n  "),
      _c("a", { attrs: { href: _vm.flag.url } }, [
        _vm._v(_vm._s(_vm.elementNameAccusative)),
      ]),
      _vm._v("\n  z powodu "),
      _c("strong", [_vm._v(_vm._s(_vm.flag.name))]),
      _vm._v("\n  dnia\n  "),
      _c("vue-timeago", { attrs: { datetime: _vm.flag.created_at } }),
      _vm._v(" "),
      _vm.flag.text
        ? _c("p", { staticClass: "mb-0" }, [_vm._v(_vm._s(_vm.flag.text))])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "vue-modal",
        {
          ref: "modal",
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return [_vm._v("Zamknięcie raportu")]
              },
              proxy: true,
            },
            {
              key: "buttons",
              fn: function () {
                return [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-secondary",
                      attrs: { type: "button", "data-dismiss": "modal" },
                      on: {
                        click: function ($event) {
                          return _vm.$refs.modal.close()
                        },
                      },
                    },
                    [_vm._v("Anuluj")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-danger danger",
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.closeFlag.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("Tak, zamknij")]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm._v(" "),
          _vm._v(" "),
          _c("p", [_vm._v("Czy na pewno chcesz zamknąć ten raport?")]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }