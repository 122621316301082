var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.tags.length
    ? _c(
        "ul",
        { staticClass: "tag-clouds" },
        _vm._l(_vm.tags, function (tag) {
          return _c(
            "li",
            [
              _c(_vm.tagName, { tag: "component", attrs: { href: tag.url } }, [
                tag.logo
                  ? _c("img", { attrs: { alt: tag.name, src: tag.logo } })
                  : _vm._e(),
                _vm._v(
                  "\n\n      " +
                    _vm._s(tag.real_name || tag.name) +
                    "\n\n      "
                ),
                _vm.editable
                  ? _c(
                      "a",
                      {
                        staticClass: "remove",
                        on: {
                          click: function ($event) {
                            return _vm.deleteTag(tag)
                          },
                        },
                      },
                      [_c("i", { staticClass: "fa fa-xmark" })]
                    )
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _vm.editable || tag.priority
                ? _c("vue-progress-bar", {
                    attrs: { editable: _vm.editable },
                    on: {
                      click: function ($event) {
                        return _vm.setPriority(tag, ...arguments)
                      },
                    },
                    model: {
                      value: tag.priority,
                      callback: function ($$v) {
                        _vm.$set(tag, "priority", $$v)
                      },
                      expression: "tag.priority",
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }