var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    _vm.tagName,
    {
      directives: [
        {
          name: "profile",
          rawName: "v-profile",
          value: _vm.user.id,
          expression: "user.id",
        },
      ],
      tag: "component",
      class: [{ "badge badge-primary": _vm.owner }, "username"],
      style: { textDecoration: this.user.is_blocked ? "line-through" : "" },
    },
    [_vm._v(_vm._s(_vm.user.name) + "\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }