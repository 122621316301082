var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "position-relative" },
    [
      _vm._t("default"),
      _vm._v(" "),
      _c("vue-dropdown", {
        ref: "dropdown",
        attrs: { items: _vm.items },
        on: { select: _vm.selectItem },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }