
import Vue from 'vue';

export default Vue.extend({
  name: 'VueModal',
  data() {
    return {
      isOpen: false,
      bodyOverflow: '',
    };
  },
  methods: {
    open() {
      this.isOpen = true;

      this.$nextTick(() => {
        // firefox hack: set focus to make Esc button works
        (this.$el as HTMLElement).focus();

        this.bodyOverflow = document.body.style.overflow;
        document.body.style.overflow = 'hidden';

        // set focus on any first element
        (this.$el.querySelectorAll('select,input')[0] as HTMLInputElement)?.focus();
      });
    },
    close() {
      this.isOpen = false;
      document.body.style.overflow = this.bodyOverflow;
    },
  },
});
