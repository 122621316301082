
import {Tag} from '@/types/models';
import axios, {AxiosResponse} from 'axios';
import Vue from "vue";
import VueDropdown from '../forms/dropdown.vue';

export default Vue.extend({
  name: 'tags-inline',
  components: {
    'vue-dropdown': VueDropdown,
  },
  props: {
    sourceUrl: {
      type: String,
      default: '/completion/prompt/tags',
    },
    placeholder: {
      type: String,
      default: '',
    },
    tags: {
      type: Array,
      default: () => [],
    },
    popularTags: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      searchText: '',
      filteredTags: [],
    };
  },
  watch: {
    searchText(val) {
      if (!val) {
        return;
      }
      axios.get<any>(this.sourceUrl, {params: {q: val}})
        .then((result: AxiosResponse<any>) => this.filteredTags = result.data);
    },
  },
  methods: {
    toggleTag(tag: Tag) {
      this.searchText = '';
      this.$refs.input.focus();

      this.$emit('change', tag);

      const searchIndex = this.popularTags.findIndex(el => el.name === tag.name);

      if (searchIndex > -1) {
        this.popularTags.splice(searchIndex, 1);
      }
    },
    selectTag(event) {
      if (this.$refs.dropdown.getSelected()) {
        this.applyTag(this.$refs.dropdown.getSelected().name);
        event.preventDefault(); // prevent submitting the form
      }
    },
    setTag() {
      let input = this.searchText.trim().toLowerCase().replace(/[^a-ząęśżźćółń0-9\-\.#\+\s]/gi, '');

      if (input.startsWith('#')) {
        input = input.substr(1);
      }

      if (input) {
        this.applyTag(input);
      }
    },
    applyTag(name: string) {
      this.toggleTag({name});
      this.$refs.dropdown.hideDropdown();
    },
  },
});
