var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("input", {
    directives: [
      {
        name: "model",
        rawName: "v-model",
        value: _vm.valueLocal,
        expression: "valueLocal",
      },
    ],
    attrs: { name: _vm.name, type: "radio" },
    domProps: {
      value: _vm.checkedValue,
      checked: _vm._q(_vm.valueLocal, _vm.checkedValue),
    },
    on: {
      change: function ($event) {
        _vm.valueLocal = _vm.checkedValue
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }