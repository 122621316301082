var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "position-relative user-avatar" }, [
    _c("img", {
      staticClass: "d-block mw-100",
      attrs: { src: _vm.url, alt: _vm.name, loading: "lazy" },
    }),
    _vm._v(" "),
    _vm.isOnline ? _c("div", { staticClass: "is-online" }) : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }