var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "thumbnail" }, [
    _c(
      "div",
      { staticClass: "position-relative img-thumbnail text-center" },
      [
        _vm.url
          ? [
              _vm.isImage
                ? _c("img", { staticClass: "mw-100", attrs: { src: _vm.url } })
                : _c("div", { staticClass: "placeholder-mask" }, [
                    _c("i", { staticClass: "far fa-file fa-2x" }),
                  ]),
            ]
          : _c("div", { staticClass: "bg-light placeholder-mask" }, [
              !_vm.isProcessing
                ? _c("i", { staticClass: "fas fa-circle-plus fa-2x" })
                : _vm._e(),
            ]),
        _vm._v(" "),
        _vm.url
          ? _c("a", {
              staticClass: "thumbnail-mask",
              attrs: { href: "javascript:" },
              on: { click: _vm.insertImage },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.url
          ? _c(
              "a",
              {
                staticClass: "btn btn-sm btn-danger delete",
                attrs: { href: "javascript:", title: "Usuń" },
                on: { click: _vm.deleteImage },
              },
              [_c("i", { staticClass: "fas fa-xmark" })]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.isProcessing
          ? _c("div", { staticClass: "spinner-mask" }, [
              _c("i", { staticClass: "fas fa-spinner fa-spin fa-2x" }),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.url && !_vm.isProcessing,
              expression: "!url && !isProcessing",
            },
          ],
          ref: "input",
          staticClass: "thumbnail-mask",
          attrs: { accept: _vm.accept, type: "file" },
          on: { change: _vm.upload },
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }