var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.totalPages > 1
    ? _c(
        "ul",
        { staticClass: "pagination" },
        [
          _vm.currentPage > 1
            ? _c("li", { staticClass: "page-item" }, [
                _c(
                  "a",
                  {
                    staticClass: "page-link",
                    attrs: {
                      href: _vm.url(_vm.currentPage - 1),
                      "aria-label": "Previous",
                    },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.change(_vm.currentPage - 1)
                      },
                    },
                  },
                  [
                    _c("span", { attrs: { "aria-hidden": "true" } }, [
                      _vm._v("«"),
                    ]),
                  ]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.slider, function (element) {
            return _c(
              "li",
              {
                staticClass: "page-item",
                class: {
                  active: element === _vm.currentPage,
                  disabled: element === "...",
                },
              },
              [
                element === _vm.currentPage
                  ? _c("span", { staticClass: "page-link" }, [
                      _vm._v(_vm._s(element)),
                    ])
                  : element !== "..."
                  ? _c(
                      "a",
                      {
                        staticClass: "page-link",
                        attrs: { href: _vm.url(element) },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.change(element)
                          },
                        },
                      },
                      [_vm._v(_vm._s(element))]
                    )
                  : _c("span", { staticClass: "page-link" }, [_vm._v("...")]),
              ]
            )
          }),
          _vm._v(" "),
          _vm.currentPage < _vm.totalPages
            ? _c("li", { staticClass: "page-item" }, [
                _c(
                  "a",
                  {
                    staticClass: "page-link",
                    attrs: {
                      href: _vm.url(_vm.currentPage + 1),
                      "aria-label": "Next",
                    },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.change(_vm.currentPage + 1)
                      },
                    },
                  },
                  [
                    _c("span", { attrs: { "aria-hidden": "true" } }, [
                      _vm._v("»"),
                    ]),
                  ]
                ),
              ])
            : _vm._e(),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }