import { render, staticRenderFns } from "./tags-inline.vue?vue&type=template&id=0ba8e580"
import script from "./tags-inline.vue?vue&type=script&lang=ts"
export * from "./tags-inline.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports