var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "editor", class: { "is-invalid": _vm.isInvalid } },
    [
      _c(
        "vue-tabs",
        {
          staticClass: "mb-2",
          attrs: {
            items: _vm.tabs,
            "current-tab": _vm.tabs.indexOf(_vm.currentTab),
            type: "pills",
          },
          on: { click: _vm.switchTab },
        },
        [
          _vm.isContent
            ? _c("div", { staticClass: "btn-toolbar ms-auto" }, [
                _c(
                  "div",
                  {
                    staticClass: "btn-group d-inline me-2 ms-2 mt-1",
                    attrs: { role: "group", "aria-label": "..." },
                  },
                  _vm._l(_vm.buttons, function (button) {
                    return _c(
                      "button",
                      {
                        staticClass: "btn btn-sm btn-control",
                        style: {
                          opacity: button.can ? "1.0" : "0.4",
                          cursor: button.can ? "pointer" : "default",
                        },
                        attrs: {
                          type: "button",
                          title: button.can ? button.title : button.break,
                        },
                        on: { click: button.click },
                      },
                      [_c("i", { class: ["fas fa-fw", button.icon] })]
                    )
                  }),
                  0
                ),
              ])
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticStyle: { position: "relative" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isContent,
                expression: "isContent",
              },
            ],
          },
          [
            _c(
              "div",
              {
                class: ["form-control", { "is-invalid": _vm.error !== null }],
                staticStyle: {
                  height: "inherit",
                  outline: "none",
                  "box-shadow": "none",
                  border: "none",
                  "padding-left": "0",
                  "padding-right": "0",
                },
              },
              [
                _c("vue-editor", {
                  directives: [
                    {
                      name: "paste",
                      rawName: "v-paste:success",
                      value: _vm.addAsset,
                      expression: "addAsset",
                      arg: "success",
                    },
                  ],
                  ref: "editor",
                  attrs: {
                    placeholder: "Kliknij, aby dodać treść...",
                    autocompleteSource: _vm.autocomplete,
                    emojiUrl: _vm.emojiUrl,
                    emojis: _vm.emojisProperty,
                  },
                  on: {
                    submit: _vm.save,
                    cancel: _vm.cancel,
                    state: _vm.updateState,
                  },
                  model: {
                    value: _vm.valueLocal,
                    callback: function ($$v) {
                      _vm.valueLocal = $$v
                    },
                    expression: "valueLocal",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("vue-error", { attrs: { message: _vm.error } }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isPreview,
              expression: "isPreview",
            },
          ],
          staticClass: "preview post-content",
          domProps: { innerHTML: _vm._s(_vm.previewHtml) },
        }),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.emojiPickerOpen,
                expression: "emojiPickerOpen",
              },
            ],
            staticClass: "emoji-picker-container",
          },
          [
            _c("vue-emoji-picker", {
              ref: "emoji-picker",
              attrs: { emojis: _vm.emojis, open: _vm.emojiPickerOpen },
              on: {
                input: _vm.insertEmoji,
                blur: _vm.blurEmojiPicker,
                close: _vm.closeEmojiPicker,
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("hr", { staticClass: "m-0" }),
      _vm._v(" "),
      _vm._t("bottom"),
      _vm._v(" "),
      _c("div", { staticClass: "pt-1 ps-2 pe-2 d-flex" }, [
        _c(
          "div",
          { staticClass: "small me-auto" },
          [
            _vm.isProcessing
              ? [
                  _c("i", { staticClass: "fas fa-spinner fa-spin small" }),
                  _vm._v(" "),
                  _c("span", { staticClass: "small" }, [
                    _vm._v(_vm._s(_vm.progress) + "%"),
                  ]),
                ]
              : _c(
                  "a",
                  {
                    staticClass: "small text-muted",
                    attrs: {
                      "aria-label": _vm.uploadTooltip,
                      tabindex: "-1",
                      "data-balloon-length": "large",
                      "data-balloon-pos": "up-left",
                      "data-balloon-nofocus": "",
                      href: "javascript:",
                    },
                    on: { click: _vm.chooseFile },
                  },
                  [
                    _c("i", { staticClass: "far fa-image" }),
                    _vm._v(" "),
                    _c("span", { staticClass: "d-none d-sm-inline" }, [
                      _vm._v(
                        "Kliknij, aby dodać załącznik lub wklej ze schowka."
                      ),
                    ]),
                  ]
                ),
            _vm._v(" "),
            _vm._t("options"),
          ],
          2
        ),
        _vm._v(" "),
        _vm._m(0),
      ]),
      _vm._v(" "),
      _vm.assets.length
        ? _c(
            "div",
            { staticClass: "pt-3 pb-3 ps-2 pe-2 d-flex" },
            _vm._l(_vm.assets, function (item) {
              return _c(
                "div",
                { key: item.id, staticClass: "col-sm-2" },
                [
                  _c("vue-thumbnail", {
                    attrs: {
                      name: "asset",
                      "data-balloon-pos": "down",
                      url: item.url,
                      "aria-label": item.name,
                    },
                    on: {
                      delete: function ($event) {
                        return _vm.deleteAsset(item)
                      },
                      insert: function ($event) {
                        return _vm.insertAssetAtCaret(item)
                      },
                    },
                  }),
                ],
                1
              )
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt-2 collapse", attrs: { id: "js-wiki-help" } },
        [_c("vue-help")],
        1
      ),
      _vm._v(" "),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "small ms-auto" }, [
      _c(
        "a",
        {
          staticClass: "small text-muted",
          attrs: {
            href: "#js-wiki-help",
            tabindex: "-1",
            "data-bs-toggle": "collapse",
          },
        },
        [
          _c("i", { staticClass: "fab fa-markdown" }),
          _vm._v("\n        Instrukcja obsługi Markdown\n      "),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }