var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "on-clickaway",
          rawName: "v-on-clickaway",
          value: _vm.blur,
          expression: "blur",
        },
      ],
      staticClass: "emoji-picker",
    },
    [
      _c("div", { staticClass: "card card-body" }, [
        _c("div", { staticClass: "triangle" }),
        _vm._v(" "),
        _c(
          "div",
          { staticStyle: { display: "flex", "flex-direction": "column" } },
          [
            _c("div", { staticClass: "emoji-grid" }, [
              _c(
                "div",
                {
                  staticClass: "emoji-scroll",
                  on: { mouseleave: _vm.mouseLeave },
                },
                _vm._l(_vm.emojis.categories, function (category) {
                  return _c("div", { staticClass: "category" }, [
                    _c("h5", [_vm._v(_vm._s(category.name))]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "category-emojis" },
                      [
                        _vm._l(category.subcategories, function (name) {
                          return _vm.subcategoryVisible(name)
                            ? _vm._l(
                                _vm.emojis.subcategories[name],
                                function (code) {
                                  return _c("img", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.visible(
                                          _vm.emojis.emoticons[code]
                                        ),
                                        expression:
                                          "visible(emojis.emoticons[code])",
                                      },
                                    ],
                                    staticClass: "emoji",
                                    attrs: {
                                      src: _vm.url(_vm.emojis.emoticons[code]),
                                      title: _vm.emojis.emoticons[code].name,
                                      alt: _vm.emojis.emoticons[code].native,
                                    },
                                    on: {
                                      mouseover: function ($event) {
                                        return _vm.mouseOver(
                                          _vm.emojis.emoticons[code]
                                        )
                                      },
                                      click: function ($event) {
                                        return _vm.select(code)
                                      },
                                    },
                                  })
                                }
                              )
                            : _vm._e()
                        }),
                      ],
                      2
                    ),
                  ])
                }),
                0
              ),
              _vm._v(" "),
              _c("div", { staticClass: "emoji-preview-box" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.emoji,
                        expression: "emoji",
                      },
                    ],
                    staticClass: "emoji-preview",
                  },
                  [
                    _c("div", { staticClass: "emoji-name" }, [
                      _c("p", { staticClass: "title" }, [
                        _vm._v(_vm._s(_vm.emoji && _vm.emoji.name)),
                      ]),
                      _vm._v(" "),
                      _c("code", { staticClass: "id" }, [
                        _vm._v(":" + _vm._s(_vm.emoji && _vm.emoji.id) + ":"),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("small", [_vm._v(_vm._s(_vm.keywords(_vm.emoji)))]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.emoji,
                        expression: "!emoji",
                      },
                    ],
                    staticClass: "emoji-placeholder",
                  },
                  [_vm._v("\n            Wybierz emoji\n          ")]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "search-box" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.searchPhrase,
                    expression: "searchPhrase",
                  },
                ],
                staticClass: "form-control",
                attrs: { placeholder: "Wyszukaj emoji..." },
                domProps: { value: _vm.searchPhrase },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.searchPhrase = $event.target.value
                  },
                },
              }),
              _vm._v(" "),
              _c(
                "button",
                {
                  ref: "close-button",
                  staticClass: "btn btn-primary",
                  attrs: { type: "button" },
                  on: { click: _vm.close },
                },
                [
                  _c("i", { staticClass: "fas fa-xmark" }),
                  _vm._v("\n          Zamknij\n        "),
                ]
              ),
            ]),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }