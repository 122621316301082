var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "card-body",
      class: {
        "not-read": !_vm.topic.is_read,
        flagged: _vm.flag != null,
        tagged: _vm.highlight,
      },
    },
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          {
            staticClass: "col-md-12 d-flex align-items-center",
            class: {
              "col-xl-9 col-lg-10": _vm.showCategoryName,
              "col-xl-10 col-lg-10": !_vm.showCategoryName,
            },
          },
          [
            _c(
              "a",
              {
                staticClass: "topic-icon me-2 d-none d-md-flex",
                class: { "not-read": !_vm.topic.is_read },
                attrs: { href: _vm.getUrl() },
                on: {
                  click: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "left", 37, $event.key, [
                        "Left",
                        "ArrowLeft",
                      ])
                    )
                      return null
                    if ("button" in $event && $event.button !== 0) return null
                    return _vm.mark.apply(null, arguments)
                  },
                },
              },
              [
                _vm.topic.is_sticky
                  ? _c("i", { staticClass: "fas fa-info" })
                  : _vm.topic.is_locked
                  ? _c("i", { staticClass: "fas fa-lock" })
                  : _c("i", { staticClass: "far fa-comment" }),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "topic-container" }, [
              _c("div", { staticClass: "topic-row" }, [
                _c("h5", { staticClass: "topic-subject text-truncate m-0" }, [
                  _vm.isAuthorized
                    ? _c(
                        "a",
                        {
                          attrs: {
                            href: "javascript:",
                            title: "Kliknij aby wł/wył obserwowanie wątku",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.subscribe(_vm.topic)
                            },
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "fa-star fa-fw",
                            class: {
                              "fas on": _vm.topic.is_subscribed,
                              far: !_vm.topic.is_subscribed,
                            },
                          }),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.topic.accepted_id
                    ? _c(
                        "a",
                        {
                          attrs: {
                            href:
                              _vm.topic.url +
                              `?p=${_vm.topic.accepted_id}#id${_vm.topic.accepted_id}`,
                          },
                        },
                        [_c("i", { staticClass: "fas fa-check" })]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      class: { "topic-unread": !_vm.topic.is_read },
                      attrs: { href: _vm.getUrl() },
                    },
                    [_vm._v(_vm._s(_vm.topic.title))]
                  ),
                  _vm._v(" "),
                  _vm.showCategoryName
                    ? _c("small", { staticClass: "d-inline d-xl-none" }, [
                        _vm._v("\n              w\n              "),
                        _c("a", { attrs: { href: _vm.topic.forum.url } }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.topic.forum.name) +
                              "\n              "
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.flag != null
                    ? _c(
                        "a",
                        {
                          attrs: {
                            href: _vm.flag,
                            title: "Przejdź do raportowanego posta",
                          },
                        },
                        [_c("i", { staticClass: "fa fa-fire" })]
                      )
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _vm.totalPages > 1
                  ? _c(
                      "div",
                      {
                        staticClass: "d-none d-sm-inline ms-2 topic-pagination",
                      },
                      [
                        _c("i", { staticClass: "far fa-file small" }),
                        _vm._v(" "),
                        _c(
                          "a",
                          { attrs: { href: _vm.topic.url + "?page=1" } },
                          [_vm._v("1")]
                        ),
                        _vm._v(" "),
                        _vm.totalPages > 4
                          ? [_vm._v("\n              ...\n            ")]
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.totalPages === 4
                          ? _c(
                              "a",
                              { attrs: { href: _vm.topic.url + "?page=2" } },
                              [_vm._v("2")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm._l(_vm.paginatorPages, function (i) {
                          return [
                            _c(
                              "a",
                              { attrs: { href: _vm.topic.url + "?page=" + i } },
                              [_vm._v(_vm._s(i))]
                            ),
                            _vm._v(" "),
                          ]
                        }),
                      ],
                      2
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "ul",
                  {
                    staticClass:
                      "topic-statistic list-inline small mt-1 mt-sm-0 mb-0 d-block d-sm-inline ms-sm-auto flex-sm-shrink-0",
                  },
                  [
                    _c(
                      "li",
                      {
                        staticClass: "list-inline-item small",
                        attrs: { title: "Liczba odpowiedzi" },
                      },
                      [
                        _c("i", {
                          staticClass: "fa-fw fa-comments",
                          class: {
                            "fas topic-has-reply": _vm.topic.is_replied,
                            far: !_vm.topic.is_replied,
                          },
                        }),
                        _vm._v(
                          " \n              " +
                            _vm._s(_vm.number(_vm.topic.replies)) +
                            "\n            "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "li",
                      {
                        staticClass: "list-inline-item small",
                        attrs: { title: "Liczba wyświetleń" },
                      },
                      [
                        _c("i", { staticClass: "far fa-fw fa-eye" }),
                        _vm._v(
                          " \n              " +
                            _vm._s(_vm.number(_vm.topic.views)) +
                            "\n            "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.topic.score > 0
                      ? _c(
                          "li",
                          {
                            staticClass: "list-inline-item small",
                            attrs: {
                              title: "Liczba głosów oddanych na ten wątek",
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "fa-fw fa-thumbs-up",
                              class: {
                                "fas text-primary": _vm.topic.is_voted,
                                far: !_vm.topic.is_voted,
                              },
                            }),
                            _vm._v(
                              " \n              " +
                                _vm._s(_vm.number(_vm.topic.score)) +
                                "\n            "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "d-flex mt-1" }, [
                _c(
                  "div",
                  { staticClass: "d-none d-lg-inline small text-truncate" },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "text-muted topic-date",
                        attrs: {
                          href:
                            _vm.topic.url +
                            `?p=${_vm.topic.first_post_id}#id${_vm.topic.first_post_id}`,
                        },
                      },
                      [
                        _c("vue-timeago", {
                          attrs: { datetime: _vm.topic.created_at },
                        }),
                      ],
                      1
                    ),
                    _vm._v(",\n\n            "),
                    _vm.topic.user
                      ? _c("vue-username", {
                          staticClass: "mt-1 topic-username",
                          attrs: { user: _vm.topic.user },
                        })
                      : _c("span", { staticClass: "topic-username" }, [
                          _vm._v(_vm._s(_vm.topic.user_name)),
                        ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.topic.tags.length
                  ? _c(
                      "ul",
                      { staticClass: "tag-clouds tag-clouds-xs" },
                      _vm._l(_vm.topic.tags, function (tag) {
                        return _c("li", [
                          _c("a", { attrs: { href: tag.url } }, [
                            _vm._v(_vm._s(tag.name)),
                          ]),
                        ])
                      }),
                      0
                    )
                  : _vm._e(),
              ]),
            ]),
          ]
        ),
        _vm._v(" "),
        _vm.showCategoryName
          ? _c(
              "div",
              {
                staticClass:
                  "col-xl-1 d-none d-xl-block text-center text-truncate",
              },
              [
                _c(
                  "a",
                  {
                    staticClass: "small",
                    attrs: {
                      href: _vm.topic.forum.url,
                      title: _vm.topic.forum.name,
                    },
                  },
                  [_vm._v(_vm._s(_vm.topic.forum.name))]
                ),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "col-xl-2 col-lg-2 col-md-12" }, [
          _c("div", { staticClass: "media m-md-0" }, [
            _c(
              "a",
              {
                directives: [
                  {
                    name: "profile",
                    rawName: "v-profile",
                    value: this.topic.last_post.user
                      ? this.topic.last_post.user.id
                      : null,
                    expression:
                      "this.topic.last_post.user ? this.topic.last_post.user.id : null",
                  },
                ],
              },
              [
                _c(
                  "vue-avatar",
                  _vm._b(
                    {
                      staticClass:
                        "i-35 me-2 d-none d-md-inline-block position-relative img-thumbnail",
                    },
                    "vue-avatar",
                    _vm.topic.last_post.user,
                    false
                  )
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "media-body small text-truncate" }, [
              _c(
                "p",
                { staticClass: "mb-0 d-inline d-md-block" },
                [
                  _vm.topic.last_post.user
                    ? _c("vue-username", {
                        staticClass: "topic-username",
                        attrs: { user: _vm.topic.last_post.user },
                      })
                    : _c("span", { staticClass: "topic-username" }, [
                        _vm._v(_vm._s(_vm.topic.last_post.user_name)),
                      ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "text-muted",
                  attrs: {
                    href:
                      _vm.topic.url +
                      `?p=${_vm.topic.last_post.id}#id${_vm.topic.last_post.id}`,
                    title: "Zobacz ostatni post",
                  },
                },
                [
                  _c("vue-timeago", {
                    attrs: { datetime: _vm.topic.last_post.created_at },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }