var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { ref: "editor", staticClass: "tag-editor dropup" },
    [
      _c(
        "ul",
        { ref: "cloud", staticClass: "tag-clouds" },
        _vm._l(_vm.popularTags.slice(0, 3), function (tag) {
          return _c("li", [
            _c(
              "a",
              {
                staticClass: "suggest",
                attrs: { "aria-label": tag.text, "data-balloon-pos": "up" },
                on: {
                  click: function ($event) {
                    return _vm.toggleTag({ name: tag.name })
                  },
                },
              },
              [
                _c("i", { staticClass: "fa fa-plus" }),
                _vm._v("\n\n        " + _vm._s(tag.name) + "\n      "),
              ]
            ),
          ])
        }),
        0
      ),
      _vm._v(" "),
      _vm.popularTags.length > 3
        ? [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "dropdown-menu p-2" }, [
              _c(
                "ul",
                { staticClass: "tag-clouds" },
                _vm._l(_vm.popularTags.slice(3), function (tag) {
                  return _c("li", [
                    _c(
                      "a",
                      {
                        staticClass: "suggest",
                        attrs: {
                          "aria-label": tag.text,
                          "data-balloon-pos": "up",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.toggleTag({ name: tag.name })
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "fa fa-plus" }),
                        _vm._v(
                          "\n\n            " + _vm._s(tag.name) + "\n          "
                        ),
                      ]
                    ),
                  ])
                }),
                0
              ),
            ]),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c(
        "ul",
        { staticClass: "tag-clouds" },
        _vm._l(_vm.tags, function (tag) {
          return _c("li", [
            _c("span", [
              _vm._v("\n        " + _vm._s(tag.name) + "\n\n        "),
              _c(
                "a",
                {
                  staticClass: "remove",
                  on: {
                    click: function ($event) {
                      return _vm.toggleTag(tag)
                    },
                  },
                },
                [_c("i", { staticClass: "fa fa-xmark" })]
              ),
            ]),
          ])
        }),
        0
      ),
      _vm._v(" "),
      _c("input", {
        ref: "input",
        attrs: {
          placeholder: _vm.placeholder,
          type: "text",
          tabindex: "4",
          autocomplete: "off",
          name: "tags",
        },
        domProps: { value: _vm.searchText },
        on: {
          input: function ($event) {
            _vm.searchText = $event.target.value
          },
          keyup: [
            function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "space", 32, $event.key, [
                  " ",
                  "Spacebar",
                ])
              )
                return null
              return _vm.setTag.apply(null, arguments)
            },
            function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              )
                return null
              return _vm.dropdown.hideDropdown.apply(null, arguments)
            },
            function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "up", 38, $event.key, ["Up", "ArrowUp"])
              )
                return null
              $event.preventDefault()
              return _vm.dropdown.goUp.apply(null, arguments)
            },
            function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "down", 40, $event.key, [
                  "Down",
                  "ArrowDown",
                ])
              )
                return null
              $event.preventDefault()
              return _vm.dropdown.goDown.apply(null, arguments)
            },
          ],
          keydown: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            )
              return null
            return _vm.selectTag.apply(null, arguments)
          },
        },
      }),
      _vm._v(" "),
      _c("vue-dropdown", {
        ref: "dropdown",
        staticClass: "tag-dropdown mt-2 w-100",
        attrs: { items: _vm.filteredTags },
        on: { select: _vm.toggleTag },
        scopedSlots: _vm._u([
          {
            key: "item",
            fn: function (slot) {
              return [
                _c("span", [_vm._v(_vm._s(slot.item.name))]),
                _vm._v(" "),
                _c("small", [
                  _vm._v(
                    "×" +
                      _vm._s(
                        slot.item.topics + slot.item.microblogs + slot.item.jobs
                      )
                  ),
                ]),
              ]
            },
          },
        ]),
      }),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "button",
      {
        staticClass: "btn btn-xs text-muted",
        attrs: {
          type: "button",
          "data-bs-toggle": "dropdown",
          "aria-label": "Dropdown",
          title: "Więcej...",
        },
      },
      [_c("i", { staticClass: "fa fa-ellipsis" })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }