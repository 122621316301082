var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "markdown-help" }, [
      _c("div", { staticClass: "card card-info" }, [
        _c("div", { staticClass: "card-header" }, [
          _vm._v("\n      Pomoc "),
          _c("span", { staticClass: "card-version" }, [_vm._v("1.18.4")]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "card-body" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-6" }, [
              _c("h3", [_vm._v("Typografia")]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n            Edytor obsługuje składnie Markdown, w której pojedynczy akcent "
                ),
                _c("code", [_vm._v("*kursywa*")]),
                _vm._v("\n            oraz "),
                _c("code", [_vm._v("_kursywa_")]),
                _vm._v(" to pochylenie. Z kolei podwójny akcent "),
                _c("code", [_vm._v("**pogrubienie**")]),
                _vm._v("\n            oraz "),
                _c("code", [_vm._v("__pogrubienie__")]),
                _vm._v(" to pogrubienie. Dodanie znaczników "),
                _c("code", [_vm._v("~~strike~~")]),
                _vm._v("\n            to przekreślenie.\n          "),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v("\n            Możesz dodać formatowanie komendami "),
                _c("i", { staticClass: "fas fa-fw fa-bold" }),
                _vm._v(",\n            "),
                _c("i", { staticClass: "fas fa-fw fa-italic" }),
                _vm._v(", "),
                _c("i", { staticClass: "fas fa-fw fa-underline" }),
                _vm._v(" oraz\n            "),
                _c("i", { staticClass: "fas fa-fw fa-strikethrough" }),
                _vm._v(".\n          "),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "text-muted" }, [
                _vm._v(
                  "\n            Ponieważ dekoracja podkreślenia jest przeznaczona na linki, markdown\n            nie zawiera specjalnej składni dla podkreślenia. Dlatego by dodać\n            podkreślenie, użyj "
                ),
                _c("code", [_vm._v("<u>underline</u>")]),
                _vm._v(".\n          "),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n            Komendy formatujące reagują na skróty klawiszowe: "
                ),
                _c("kbd", [_vm._v("Ctrl+B")]),
                _vm._v(",\n            "),
                _c("kbd", [_vm._v("Ctrl+I")]),
                _vm._v(", "),
                _c("kbd", [_vm._v("Ctrl+U")]),
                _vm._v(" oraz "),
                _c("kbd", [_vm._v("Ctrl+S")]),
                _vm._v(".\n          "),
              ]),
              _vm._v(" "),
              _c("h3", [_vm._v("Linki")]),
              _vm._v(" "),
              _c("p", [
                _vm._v("\n            By dodać link w edytorze użyj komendy "),
                _c("i", { staticClass: "fa fa-link" }),
                _vm._v(" lub\n            użyj składni "),
                _c("code", [_vm._v("[title](link)")]),
                _vm._v(
                  ". URL umieszczony w linku lub nawet\n            URL umieszczony bezpośrednio w tekście będzie aktywny i klikalny.\n          "
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n            Jeżeli chcesz, możesz samodzielnie dodać link: "
                ),
                _c("code", [_vm._v('<a href="link">title</a>')]),
                _vm._v(".\n          "),
              ]),
              _vm._v(" "),
              _c("h4", [_vm._v("Wewnętrzne odnośniki")]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n            Możesz umieścić odnośnik do wewnętrznej podstrony, używając następującej\n            składnii: "
                ),
                _c("code", [_vm._v("[[Delphi/Kompendium]]")]),
                _vm._v(" lub "),
                _c("code", [
                  _vm._v(
                    "[[Delphi/Kompendium|kliknij,\n            aby przejść do kompendium]]"
                  ),
                ]),
                _vm._v(
                  ". Odnośniki mogą prowadzić do Forum 4programmers\n            lub np. do Kompendium.\n          "
                ),
              ]),
              _vm._v(" "),
              _c("h3", [_vm._v("Wspomnienia użytkowników")]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n            By wspomnieć użytkownika forum, wpisz w formularzu znak "
                ),
                _c("code", [_vm._v("@")]),
                _vm._v(
                  ". Zobaczysz\n            okienko autouzupełniające nazwy użytkowników. Autouzupełnienie dobierze odpowiedni\n            format wspomnienia, zależnie od tego czy w nazwie użytkownika znajduje się spacja.\n          "
                ),
              ]),
              _vm._v(" "),
              _c("h3", [_vm._v("Znaczniki HTML")]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n            Dozwolone jest używanie niektórych znaczników HTML: "
                ),
                _c("code", [_vm._v("<a>")]),
                _vm._v(",\n            "),
                _c("code", [_vm._v("<b>")]),
                _vm._v(", "),
                _c("code", [_vm._v("<i>")]),
                _vm._v(", "),
                _c("code", [_vm._v("<kbd>")]),
                _vm._v(", "),
                _c("code", [_vm._v("<del>")]),
                _vm._v(",\n            "),
                _c("code", [_vm._v("<strong>")]),
                _vm._v(", "),
                _c("code", [_vm._v("<dfn>")]),
                _vm._v(", "),
                _c("code", [_vm._v("<pre>")]),
                _vm._v(",\n            "),
                _c("code", [_vm._v("<blockquote>")]),
                _vm._v(",\n            "),
                _c("code", [_vm._v("<hr/>")]),
                _vm._v(", "),
                _c("code", [_vm._v("<sub>")]),
                _vm._v(", "),
                _c("code", [_vm._v("<sup>")]),
                _vm._v(" oraz\n            "),
                _c("code", [_vm._v("<img/>")]),
                _vm._v(".\n          "),
              ]),
              _vm._v(" "),
              _c("h4", [_vm._v("Skróty klawiszowe")]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n            Dodaj kombinację klawiszy komendą notacji klawiszy "
                ),
                _c("i", { staticClass: "fas fa-keyboard" }),
                _vm._v("\n            lub skrótem klawiszowym "),
                _c("kbd", [_vm._v("Alt+K")]),
                _vm._v(".\n          "),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n            Reprezentuj kombinacje klawiszowe używając taga "
                ),
                _c("code", [_vm._v("<kbd>")]),
                _vm._v(
                  ". Oddziel\n            od siebie klawisze znakiem plus, np "
                ),
                _c("code", [_vm._v("<kbd>Alt+Tab</kbd>")]),
                _vm._v(".\n          "),
              ]),
              _vm._v(" "),
              _c("h4", [_vm._v("Indeks górny oraz dolny")]),
              _vm._v(" "),
              _c("p", [
                _vm._v("\n            Przykład: wpisując "),
                _c("code", [_vm._v("H<sub>2</sub>O")]),
                _vm._v(" i "),
                _c("code", [_vm._v("m<sup>2</sup>")]),
                _vm._v(" otrzymasz:\n            H"),
                _c("sub", [_vm._v("2")]),
                _vm._v("O i m"),
                _c("sup", [_vm._v("2")]),
                _vm._v(".\n          "),
              ]),
              _vm._v(" "),
              _c("h3", [_vm._v("Składnia Tex")]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n            By precyzyjnie wyrazić działanie matematyczne, użyj składni Tex.\n          "
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _c("code", [
                  _vm._v(
                    "<tex>arcctg(x) = argtan(\\frac{1}{x}) = arcsin(\\frac{1}{\\sqrt{1+x^2}})</tex>"
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-6" }, [
              _c("h3", [_vm._v("Kod źródłowy")]),
              _vm._v(" "),
              _c("h4", [_vm._v("Krótkie fragmenty kodu")]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n            Wszelkie jednolinijkowe instrukcje języka programowania powinny być zawarte\n            pomiędzy obrócone apostrofy: "
                ),
                _c("code", [_vm._v("`kod instrukcji`")]),
                _vm._v(" lub\n            "),
                _c("code", [_vm._v("``console.log(`template`);``")]),
                _vm._v(".\n          "),
              ]),
              _vm._v(" "),
              _c("h4", [_vm._v("Kod wielolinijkowy")]),
              _vm._v(" "),
              _c("p", [
                _vm._v("\n            Dodaj fragment kodu komendą "),
                _c("i", { staticClass: "fas fa-fw fa-code" }),
                _vm._v(
                  ". Fragmenty\n            kodu zajmujące całą lub więcej linijek powinny być umieszczone w wielolinijkowym\n            fragmencie kodu. Znaczniki "
                ),
                _c("code", [_vm._v("```")]),
                _vm._v(" lub "),
                _c("code", [_vm._v("~~~")]),
                _vm._v(
                  " umożliwiają\n            kolorowanie różnych języków programowania. Możemy nadać nazwę języka programowania\n            używając auto-uzupełnienia, kod został pokolorowany używając konkretnych ustawień\n            kolorowania składnii:\n          "
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _c("code", [
                  _vm._v("\n              ```javascript"),
                  _c("br"),
                  _vm._v("\n              document.write('Hello World');"),
                  _c("br"),
                  _vm._v("\n              ```"),
                  _c("br"),
                ]),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n            Możesz zaznaczyć również już wklejony kod w edytorze, i użyć\n            komendy "
                ),
                _c("i", { staticClass: "fas fa-fw fa-code" }),
                _vm._v(
                  " by zamienić go w kod. Możesz użyć też kombinacji\n            "
                ),
                _c("kbd", [_vm._v("Ctrl+`")]),
                _vm._v(
                  ", by dodać fragment kodu bez oznaczników języka.\n          "
                ),
              ]),
              _vm._v(" "),
              _c("h3", [_vm._v("Tabelki")]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n            Dodaj przykładową tabelkę używając komendy "
                ),
                _c("i", { staticClass: "fas fa-fw fa-table" }),
                _vm._v(
                  ". Przykładowa tabelka\n            składa się z dwóch kolumn, nagłówka i jednego wiersza.\n          "
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  '\n            Wygeneruj tabelkę na podstawie tzw. "blueprint". Oddziel komórki separatorem '
                ),
                _c("code", [_vm._v(";")]),
                _vm._v(" lub "),
                _c("code", [_vm._v("|")]),
                _vm._v(
                  ",\n            a następnie zaznacz kursorem blueprint.\n          "
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _c("code", [_vm._v("nazwisko;dziedzina;odkrycie")]),
                _c("br"),
                _vm._v(" "),
                _c("code", [
                  _vm._v("Pitagoras;mathematics;Pythagorean Theorem"),
                ]),
                _c("br"),
                _vm._v(" "),
                _c("code", [
                  _vm._v("Albert Einstein;physics;General Relativity"),
                ]),
                _c("br"),
                _vm._v(" "),
                _c("code", [
                  _vm._v(
                    "Marie Curie, Pierre Curie;chemistry;Radium, Polonium"
                  ),
                ]),
                _c("br"),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v("Użyj komendy "),
                _c("i", { staticClass: "fas fa-fw fa-table" }),
                _vm._v(
                  " by zamienić zaznaczony blueprint na tabelkę Markdown."
                ),
              ]),
              _vm._v(" "),
              _c("h3", [_vm._v("Lista uporządkowana i nieuporządkowana")]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n            Możliwe jest tworzenie listy numerowanych oraz wypunktowanych. Wystarczy, że pierwszym znakiem linii\n            będzie "
                ),
                _c("code", [_vm._v("*")]),
                _vm._v(" lub "),
                _c("code", [_vm._v("-")]),
                _vm._v(" dla listy nieuporządkowanej oraz "),
                _c("code", [_vm._v("1. ")]),
                _vm._v(" dla\n            listy uporządkowanej.\n          "),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v("Użyj komendy "),
                _c("i", { staticClass: "fas fa-fw fa-list-ol" }),
                _vm._v(" by dodać listę uporządkowaną."),
              ]),
              _vm._v(" "),
              _c("p", [
                _c("code", [_vm._v("1. Lista numerowana")]),
                _c("br"),
                _vm._v(" "),
                _c("code", [_vm._v("2. Lista numerowana")]),
                _c("br"),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v("Użyj komendy "),
                _c("i", { staticClass: "fas fa-fw fa-list-ul" }),
                _vm._v(" by dodać listę nieuporządkowaną."),
              ]),
              _vm._v(" "),
              _c("p", [
                _c("code", [_vm._v("* Lista wypunktowana")]),
                _c("br"),
                _vm._v(" "),
                _c("code", [_vm._v("* Lista wypunktowana")]),
                _c("br"),
                _vm._v(" "),
                _c("code", [_vm._v("** Lista wypunktowana (drugi poziom)")]),
                _c("br"),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-6" }, [
              _c("h3", [_vm._v("Składnia Markdown")]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n            Edytor obsługuje składnię Markdown, która składa się ze znaków specjalnych.\n            Dostępne komendy, jak formatowanie "
                ),
                _c("i", { staticClass: "fas fa-fw fa-bold" }),
                _vm._v(", dodanie\n            tabelki "),
                _c("i", { staticClass: "fas fa-fw fa-table" }),
                _vm._v(" lub fragmentu kodu "),
                _c("i", { staticClass: "fas fa-fw fa-code" }),
                _vm._v(
                  "\n            są w pewnym sensie swiadome otaczającej jej składni, i postarają się uniknać\n            uszkodzenia jej.\n          "
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n            Dla przykładu, używając tylko dostępnych komend, nie możemy dodać formatowania\n            pogrubienia do kodu wielolinijkowego, albo dodać listy do tabelki - mogłoby\n            to doprowadzić do uszkodzenia składni.\n          "
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n            W pewnych odosobnionych przypadkach brak nowej linii przed elementami markdown\n            również mógłby uszkodzić składnie, dlatego edytor dodaje brakujące nowe linie.\n            Dla przykładu, dodanie formatowania pochylenia zaraz po tabelce, mogłoby zostać\n            błędne zinterpretowane, więc edytor doda oddzielającą nową linię pomiędzy tabelką,\n            a pochyleniem.\n          "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-6" }, [
              _c("h3", [_vm._v("Skróty klawiszowe")]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n            Skróty formatujące, kiedy w edytorze znajduje się pojedynczy kursor, wstawiają\n            sformatowany tekst przykładowy. Jeśli w edytorze znajduje się zaznaczenie (słowo,\n            linijka, paragraf), wtedy zaznaczenie zostaje sformatowane.\n          "
                ),
              ]),
              _vm._v(" "),
              _c("ul", [
                _c("li", [
                  _c("kbd", [_vm._v("Ctrl+B")]),
                  _vm._v(" - dodaj pogrubienie lub pogrub zaznaczenie"),
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("kbd", [_vm._v("Ctrl+I")]),
                  _vm._v(" - dodaj pochylenie lub pochyl zaznaczenie"),
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("kbd", [_vm._v("Ctrl+U")]),
                  _vm._v(" - dodaj podkreślenie lub podkreśl zaznaczenie"),
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("kbd", [_vm._v("Ctrl+S")]),
                  _vm._v(" - dodaj przekreślenie lub przekreśl zaznaczenie"),
                ]),
              ]),
              _vm._v(" "),
              _c("p", [_vm._v("Notacja Klawiszy")]),
              _vm._v(" "),
              _c("ul", [
                _c("li", [
                  _c("kbd", [_vm._v("Alt+K")]),
                  _vm._v(" - dodaj notację klawiszy"),
                ]),
              ]),
              _vm._v(" "),
              _c("p", [_vm._v("Fragment kodu bez oznacznika")]),
              _vm._v(" "),
              _c("ul", [
                _c("li", [
                  _c("kbd", [_vm._v("Alt+C")]),
                  _vm._v(" - dodaj pusty fragment kodu"),
                ]),
              ]),
              _vm._v(" "),
              _c("p", [_vm._v("Skróty operujące na kodzie i linijkach:")]),
              _vm._v(" "),
              _c("ul", [
                _c("li", [
                  _c("kbd", [_vm._v("Alt+L")]),
                  _vm._v(" - zaznaczenie całej linii"),
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("kbd", [
                    _vm._v("Alt+"),
                    _c("i", { staticClass: "fas fa-arrow-up" }),
                  ]),
                  _vm._v(", "),
                  _c("kbd", [
                    _vm._v("Alt+"),
                    _c("i", { staticClass: "fas fa-arrow-down" }),
                  ]),
                  _vm._v(
                    " -\n              przeniesienie linijki w której znajduje się kursor w górę/dół.\n            "
                  ),
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("kbd", [_vm._v("Tab")]),
                  _vm._v("/"),
                  _c("kbd", [_vm._v("⌘+]")]),
                  _vm._v(" - dodaj wcięcie (wcięcie w prawo)"),
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("kbd", [_vm._v("Shit+Tab")]),
                  _vm._v("/"),
                  _c("kbd", [_vm._v("⌘+[")]),
                  _vm._v(" - usunięcie wcięcia (wycięcie w lewo)"),
                ]),
              ]),
              _vm._v(" "),
              _c("p", [_vm._v("Dodawanie postów:")]),
              _vm._v(" "),
              _c("ul", [
                _c("li", [
                  _c("kbd", [_vm._v("Ctrl+Enter")]),
                  _vm._v(" - dodaj post"),
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("kbd", [_vm._v("⌘+Enter")]),
                  _vm._v(" - dodaj post (MacOS)"),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }