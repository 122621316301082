var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: `nav nav-${this.type}` },
    [
      _vm._l(_vm.items, function (item, key) {
        return _c("div", { key: key, staticClass: "nav-item" }, [
          _c(
            "a",
            {
              staticClass: "nav-link",
              class: { active: key === _vm.currentTab },
              attrs: { href: typeof key === "string" ? key : "javascript:" },
              on: {
                click: function ($event) {
                  return _vm.$emit("click", key)
                },
              },
            },
            [_vm._v(_vm._s(item))]
          ),
        ])
      }),
      _vm._v(" "),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }