var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "progress-bar-dotted" },
    _vm._l(_vm.max, function (i) {
      return _c(
        "span",
        {
          class: { editable: _vm.editable },
          attrs: {
            "aria-label": _vm.tooltips[i - 1],
            "data-balloon-pos": "down",
          },
          on: {
            click: function ($event) {
              return _vm.setValue(i)
            },
          },
        },
        [
          _c("i", {
            staticClass: "fas fa-circle",
            class: {
              "text-primary": _vm.valueLocal >= i,
              "text-muted": _vm.valueLocal < i,
            },
          }),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }