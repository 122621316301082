var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ol",
    {
      directives: [
        {
          name: "on-clickaway",
          rawName: "v-on-clickaway",
          value: _vm.hideDropdown,
          expression: "hideDropdown",
        },
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isDropdownVisible,
          expression: "isDropdownVisible",
        },
      ],
      ref: "dropdown",
      staticClass: "auto-complete",
    },
    _vm._l(_vm.items, function (item, index) {
      return _c(
        "li",
        {
          key: index,
          class: { hover: index === _vm.selectedIndex },
          on: {
            click: _vm.selectItem,
            mouseover: function ($event) {
              return _vm.hoverItem(index)
            },
          },
        },
        [
          _vm._t(
            "item",
            function () {
              return [
                _c("vue-avatar", {
                  staticClass: "d-inline-block",
                  attrs: { photo: item.photo, name: item.name },
                }),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(item.name))]),
                _vm._v(" "),
                item.group
                  ? _c("small", { staticClass: "badge badge-secondary" }, [
                      _vm._v(_vm._s(item.group)),
                    ])
                  : _vm._e(),
              ]
            },
            { item: item }
          ),
        ],
        2
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }