var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isOpen,
          expression: "isOpen",
        },
      ],
      staticClass: "modal fade background-darken",
      class: { "d-block": _vm.isOpen, show: _vm.isOpen },
      attrs: { tabindex: "-1", role: "dialog" },
      on: {
        click: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          return _vm.close()
        },
        keyup: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          )
            return null
          return _vm.close()
        },
      },
    },
    [
      _c("div", { staticClass: "modal-dialog" }, [
        _c("div", { staticClass: "modal-content" }, [
          _c("div", { staticClass: "modal-header" }, [
            _c(
              "h4",
              { staticClass: "modal-title" },
              [
                _vm._t("title", function () {
                  return [_vm._v("\n            Błąd\n          ")]
                }),
              ],
              2
            ),
          ]),
          _vm._v(" "),
          _vm.$slots.default
            ? _c("div", { staticClass: "modal-body" }, [_vm._t("default")], 2)
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "modal-footer" },
            [
              _vm._t("buttons", function () {
                return [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-secondary",
                      attrs: { type: "button", "data-dismiss": "modal" },
                      on: {
                        click: function ($event) {
                          return _vm.close()
                        },
                      },
                    },
                    [_vm._v("OK")]
                  ),
                ]
              }),
            ],
            2
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }