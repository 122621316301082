var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "select",
    {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.valueLocal,
          expression: "valueLocal",
        },
      ],
      staticClass: "form-control",
      class: { "is-invalid": _vm.isInvalid },
      attrs: { name: _vm.name },
      on: {
        change: function ($event) {
          var $$selectedVal = Array.prototype.filter
            .call($event.target.options, function (o) {
              return o.selected
            })
            .map(function (o) {
              var val = "_value" in o ? o._value : o.value
              return val
            })
          _vm.valueLocal = $event.target.multiple
            ? $$selectedVal
            : $$selectedVal[0]
        },
      },
    },
    [
      _vm.placeholder
        ? _c("option", { domProps: { value: null } }, [
            _vm._v(_vm._s(_vm.placeholder)),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.options, function (value, key) {
        return _c("option", {
          domProps: { value: key, innerHTML: _vm._s(value) },
        })
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }